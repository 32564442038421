import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, OutlinedInput, InputAdornment, Button, Box } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";
import { formatHeight } from "../../../common/Utils/CommonFunctions/CommonFunctions";


const BariatricMattress = ({ handleClose, preAuthFormData = 'value' }) => {
    const { t } = useTranslation();

    return (
        <>
            {preAuthFormData && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={true}
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> {t("PreAuthForm.bariatricMattress")} </DialogTitle>

                    <DialogContent>
                        <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor"> *</span> {t("PreAuthForm.requiredField")}</Typography>

                        {preAuthFormData[0]?.BranchCode && <Grid container>
                            <Grid xs={12} md={6} className={styles.spacingTop} sx={{ marginTop: '0px !important' }}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}> {t("PreAuthForm.branchCode")} <span className="asteriskSignColor"> *</span> </Typography>
                                    </div>
                                    <div >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={preAuthFormData[0]?.BranchCode}
                                            disabled
                                            sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              },}}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>}

                        {preAuthFormData[0]?.PatientHeight && <Grid container>
                            <Grid item xs={12} md={6} className={styles.spacingTop} >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>1. {t('PreAuthForm.patientsHeight')}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={formatHeight(preAuthFormData[0].PatientHeight)}
                                            sx={{ marginTop: '0px', width: "220px", borderRadius:"8px",  backgroundColor: 'var(--color-disableField)','& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              }, }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>}

                        {preAuthFormData[0]?.PatientWeight && <Grid container>
                            <Grid item xs={12} md={6} className={styles.spacingTop} >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>2. {t('PreAuthForm.patientsWeight')}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <OutlinedInput 
                                            margin="normal" 
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={preAuthFormData[0].PatientWeight}
                                            sx={{ width: "220px", borderRadius:"8px", backgroundColor: 'var(--color-disableField)','& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              }, }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                            endAdornment={<InputAdornment position="end">{t('PreAuthForm.lbs')}</InputAdornment>}
                                            />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>}


                        {preAuthFormData[0]?.IsFacilityRequested !== "" && preAuthFormData[0]?.IsFacilityRequested !== undefined && <Grid container >
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>3.</div> <div>Is this requested by SNF/ALF as their facility standard? <span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsFacilityRequested}
                                        sx={{paddingLeft:'15px'}}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}



                        {preAuthFormData[0]?.FacilityRequestingName && <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.nameOfFacility')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    minRows={2}
                                    aria-label="requesting custom text"
                                    size="small"
                                    fullWidth
                                    value={preAuthFormData[0]?.FacilityRequestingName}
                                    disabled
                                    sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}

                        {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 4. {t('PreAuthForm.physicianOrder')} <span className="asteriskSignColor"> *</span> </FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle:"italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsPhysicianOrder}
                                        sx={{paddingLeft:'15px'}}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}

                        {preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.clinicalName')} <span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinician text field"
                                    value={preAuthFormData[0]?.ClinicianName}
                                    fullWidth
                                    minRows={2}
                                    size="small"
                                    disabled
                                    sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}

                        {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.edAdmin")} <span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0]?.AdministratorOrED}
                                    size="small"
                                    disabled
                                    sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}


                        {preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.avpo")} <span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0]?.AVPO}
                                    size="small"
                                    disabled
                                    sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}

                    </DialogContent>

                    <DialogActions style={{ justifyContent: "end", padding: "14px 16px" }}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className='secondaryButton'
                        >
                            {t('NewOrder.close')}
                        </Button>
                    </DialogActions>

                </Dialog>
            )}

        </>
    )
}

BariatricMattress.formName = "BariatricMattress";
export default BariatricMattress;
