import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, Box, Checkbox, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Button } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";


const HighFlowNasalCannula = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();

    return (
        <div>
            <>
                {preAuthFormData && (
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={true}
                        onClose={handleClose}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> {t("PreAuthForm.highFlowNasalCannula")}  </DialogTitle>


                        <DialogContent>
                            <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor"> *</span> {t("PreAuthForm.requiredField")}</Typography>

                            {preAuthFormData[0]?.BranchCode && <Grid container>
                                <Grid xs={12} md={6} className={styles.spacingTop} sx={{ marginTop: '0px !important' }}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <div>
                                            <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}> {t("PreAuthForm.branchCode")} <span className="asteriskSignColor"> *</span> </Typography>
                                        </div>
                                        <div >
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                size="small"
                                                autoFocus
                                                variant="outlined"
                                                id="name"
                                                type="text"
                                                value={preAuthFormData[0]?.BranchCode}
                                                disabled
                                                sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                                    '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                  },}}
                                                inputProps={{ style: { cursor: 'not-allowed' } }}
                                            />
                                        </div>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.IsPatientOnHighFlowNasalCannula !== "" && preAuthFormData[0]?.IsPatientOnHighFlowNasalCannula !== undefined && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. {t("PreAuthForm.currentlyHighFlowNasal")} <span className="asteriskSignColor"> *</span></FormLabel>
                                        <RadioGroup
                                            value={preAuthFormData[0]?.IsPatientOnHighFlowNasalCannula}
                                            sx={{paddingLeft:'15px'}}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.IsHighFlowNasalCannulaRefill !== "" && preAuthFormData[0]?.IsHighFlowNasalCannulaRefill !== undefined && <Grid container sx={{ paddingLeft: '15px' }}>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. {t("PreAuthForm.isThisRefill")} </FormLabel>
                                        <RadioGroup
                                            value={preAuthFormData[0]?.IsHighFlowNasalCannulaRefill}
                                            sx={{paddingLeft:'15px'}}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.ClinicalRationaleForLiquidOxygen !== "" && preAuthFormData[0]?.ClinicalRationaleForLiquidOxygen !== undefined && <Grid container sx={{ paddingLeft: '15px' }}>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>b.&nbsp;</div><div> {t("PreAuthForm.liquidRationale")} </div>
                                        </Typography>
                                    </div>

                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        size="small"
                                        value={preAuthFormData[0]?.ClinicalRationaleForLiquidOxygen}
                                        aria-label="minimum height"
                                        disabled
                                        sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 2. {t('PreAuthForm.physicianOrder')} <span className="asteriskSignColor"> *</span> </FormLabel>
                                        <Box component="span" sx={{ fontWeight: 'bold', fontStyle:"italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                        <RadioGroup
                                            value={preAuthFormData[0]?.IsPhysicianOrder}
                                            sx={{paddingLeft:'15px'}}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.IsHighFlowOxygenNeedsAbove15LMin !== "" && preAuthFormData[0]?.IsHighFlowOxygenNeedsAbove15LMin !== undefined && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"> <div>3.&nbsp;</div> <div>{t("PreAuthForm.highFlowGreater15")} <span className="asteriskSignColor"> *</span></div></FormLabel>
                                        <RadioGroup
                                            value={preAuthFormData[0]?.IsHighFlowOxygenNeedsAbove15LMin}
                                            sx={{paddingLeft:'15px'}}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                            <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.CurrentLiterFlowOrdered && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            4. {t("PreAuthForm.currentLiterFlow")} <span className="asteriskSignColor"> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        aria-label="current liter flow field"
                                        minRows={2}
                                        value={preAuthFormData[0]?.CurrentLiterFlowOrdered}
                                        size="small"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.OxygenDeliveryMethod !== "" && preAuthFormData[0]?.OxygenDeliveryMethod !== undefined && <Grid container sx={{ paddingLeft: '15px' }}>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> a. Delivery: <span className="asteriskSignColor"> *</span></FormLabel>
                                        <RadioGroup
                                            value={preAuthFormData[0]?.OxygenDeliveryMethod}
                                            sx={{paddingLeft:'15px'}}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={true}
                                                        checked={preAuthFormData[0]?.OxygenDeliveryMethod === 10 || preAuthFormData[0]?.OxygenDeliveryMethod === 11}
                                                    />
                                                }
                                                label="Nasal Cannula"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={true}
                                                        checked={preAuthFormData[0]?.OxygenDeliveryMethod === 1 || preAuthFormData[0]?.OxygenDeliveryMethod === 11}
                                                    />
                                                }
                                                label="Mask"
                                            />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.DiagnosisSupportingItemApproval && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>5.&nbsp;</div> <div>{t("PreAuthForm.diagnosisSupportApproval")} <span className="asteriskSignColor"> *</span></div>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        rows={3}
                                        size="small"
                                        value={preAuthFormData[0]?.DiagnosisSupportingItemApproval}
                                        aria-label=" diagnosis support"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.OtherExtenuatingCircumstances && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>6.&nbsp;</div> <div>{t("PreAuthForm.extenuatingCircumstancesNasalCannula")} <span className="asteriskSignColor"> *</span></div>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        size="small"
                                        value={preAuthFormData[0]?.OtherExtenuatingCircumstances}
                                        aria-label="minimum extenuating"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.ClinicianName && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            {t('PreAuthForm.clinicalName')} <span className="asteriskSignColor"> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        aria-label="clinician field"
                                        value={preAuthFormData[0]?.ClinicianName}
                                        fullWidth
                                        minRows={2}
                                        size="small"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}


                            {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            {t("PreAuthForm.edAdmin")} <span className="asteriskSignColor"> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        aria-label="Admin field"
                                        minRows={2}
                                        value={preAuthFormData[0]?.AdministratorOrED}
                                        size="small"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                            {preAuthFormData[0]?.AVPO && <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            {t("PreAuthForm.avpo")} <span className="asteriskSignColor"> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        aria-label="avpo field"
                                        minRows={2}
                                        value={preAuthFormData[0]?.AVPO}
                                        size="small"
                                        disabled
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>}

                        </DialogContent>

                        <DialogActions style={{ justifyContent: "end", padding: "14px 16px" }}>
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                className='secondaryButton'
                            >
                                {t('NewOrder.close')}
                            </Button>
                        </DialogActions>

                    </Dialog>
                )}
            </>
        </div>
    )
}

HighFlowNasalCannula.formName = "HighFlowNasalCannula";
export default HighFlowNasalCannula;