import React from "react";
import { Typography, Button, FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';


const WoundVacAuthorizationForm = ({handleClose, preAuthFormData}) => {
    return (
      <>
      {preAuthFormData  && (
           
           <Dialog 
           fullWidth
           maxWidth="md"
           open={true} 
           onClose={handleClose} 
           >
           <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Negative Pressure Wound Therapy (NPWT) or Wound Vac Pre-Authorization Documentation  </DialogTitle>
           
           
           <DialogContent>
           <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
              {preAuthFormData[0]?.BranchCode != undefined && preAuthFormData[0].BranchCode !== '' && <Grid container>
               <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
               <FormControl className={styles.spacingRemoveTop}>  
                 <div>
                   <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
                 </div>
                 <div >
                 <TextField
                     margin="normal"
                     id="name"
                     autoFocus
                     fullWidth
                     variant="outlined"
                     type="text"
                     size="small"
                     value={preAuthFormData[0].BranchCode}
                     maxLength="3"
                     sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                     disabled={true}
                     inputProps={{ style: { cursor: 'not-allowed' } }}
                 />
                 </div>
                 </FormControl>
               </Grid>
             </Grid>}
   
      <Grid container>
        <Grid xs={12}>
        <Typography>
          Typically, NPWT/Wound Vac is only considered appropriate for a hospice patient either
          <span style={{ fontWeight: 'bold' }}>a.</span> for a brief transition period to allow for transition to hospice standard wound care or
          <span style={{ fontWeight: 'bold' }}>b.</span> wound is producing copious amounts of drainage which prevents hospice from being able to manage the patient in a manner which is comfortable for the patient.
        </Typography>
        </Grid>
        <Grid>
          <Typography>1. Does the patient meet either criteria where a NPWT/Wound Vac is considered appropriate?</Typography>
        </Grid>

        
    {preAuthFormData[0]?.HasBriefPeriod !== undefined && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. for a brief transition period to allow for transition to hospice standard wound care.<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
         value={preAuthFormData[0].HasBriefPeriod}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
   </Grid>}

   {preAuthFormData[0]?.TransitionDate !== undefined && preAuthFormData[0]?.TransitionDate !== '' && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'20px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">i. Planned transition date:<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
         value={preAuthFormData[0].TransitionDate}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
   </Grid>}

   {preAuthFormData[0]?.HasWoundVac !== undefined && preAuthFormData[0]?.HasWoundVac !== '' && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">b. wound is producing copious amounts of drainage which prevents hospice from being able to manage the patient in a manner which is comfortable for the patient.<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
         value={preAuthFormData[0].HasWoundVac}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
   </Grid>}

   {preAuthFormData[0]?.Drainage !== undefined && preAuthFormData[0].Drainage !== '' && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'20px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">i. How much drainage is patient having in a 24-hour period?
                    Please send documentation to support.<span className={styles.asterisk}>*</span></FormLabel>
                
                    <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].Drainage}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
       </FormControl>
   </Grid>}

  </Grid>

  <Grid container>
  {preAuthFormData[0]?.HasDrainage !== undefined && <Grid container>
     <Grid xs={12} className={styles.spacingTop}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. Does the patient currently have a NPWT/Wound Vac that would be transferred over to hospice?<span className={styles.asterisk}>*</span></FormLabel>
         <RadioGroup
           value={preAuthFormData[0].HasDrainage}
           row
           aria-labelledby="demo-row-radio-buttons-group-label"
           name="row-radio-buttons-group"
         >
           <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           <FormControlLabel value="No" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
           
         </RadioGroup>
       </FormControl>
     </Grid>
   </Grid>}

   {preAuthFormData[0]?.Vendor !== undefined && preAuthFormData[0].Vendor !== '' && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
   a. If yes, provide the name and phone number of vendor:<span className={styles.asterisk}>*</span></FormLabel>
                
                    <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].Vendor}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
       </FormControl>
   </Grid>}
   {preAuthFormData[0]?.Necessity !== undefined && preAuthFormData[0].Necessity !== '' && <Grid xs={12} className={styles.spacingTop} sx={{paddingLeft:'10px'}}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
    b. What is the clinical rationale for medical necessity of a NPWT/Wound Vac?<span className={styles.asterisk}>*</span></FormLabel>
                
    <TextField
               fullWidth
               aria-label="minimum height"
               multiline
               rows={3}
               maxLength={4000}
               size="small"
               value={preAuthFormData[0].Necessity}
               disabled={true}
               sx={{marginTop:'5px',
               resize: 'verical',
               overflow: 'auto', 
               maxHeight: '300px',
               backgroundColor: "var(--color-disableField)"
               }}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
       </FormControl>
   </Grid>}


  </Grid>

  {preAuthFormData[0]?.WoundLocation !== undefined && preAuthFormData[0].WoundLocation !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}>
     <FormControl className={styles.spacingRemoveTop}>  
   <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
   3. Please describe the wound location, size, depth, and drainage:<span className={styles.asterisk}>*</span></FormLabel>
   <TextField
               fullWidth
               aria-label="minimum height"
               multiline
               rows={3}
               maxLength={500}
               size="small"
               value={preAuthFormData[0].WoundLocation}
               disabled={true}
               sx={{marginTop:'5px',
               resize: 'verical',
               overflow: 'auto', 
               maxHeight: '300px',
               backgroundColor: "var(--color-disableField)"
               }}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
       </FormControl>
     </Grid>
   </Grid>}
   
   
   
   
   {preAuthFormData[0]?.LengthUse !== undefined && preAuthFormData[0].LengthUse !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
            4.Estimated length of use:
       </Typography>
     </div>
     <TextField
               fullWidth
               aria-label="minimum height"
               minRows={2}
               maxLength={200}
               size="small"
               value={preAuthFormData[0].LengthUse}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               disabled={true}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0]?.isRequestedBySNFALF !== undefined && <Grid container>
     <Grid xs={12}>
     <FormControl className={styles.spacingRemoveTop}>  
     <FormGroup>
         <FormControlLabel control={<Checkbox checked={preAuthFormData[0].isRequestedBySNFALF} disabled={true} />} label="Requested by SNF/ALF as their facility standard" />
       </FormGroup>
       </FormControl>
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0]?.RequestingFacility !== undefined && preAuthFormData[0].RequestingFacility !== '' && <Grid container>
     <Grid xs={12} className={styles.spacingTop}> 
     <div>
       <Typography className={styles.labelText}>
               Name of requesting facility
       </Typography>
     </div>
     <TextField
               minRows={2}
               maxLength={200}
               fullWidth
               size="small"
               disabled={true}
               aria-label="requestingFacility field"
               value={preAuthFormData[0].RequestingFacility}
               sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
               inputProps={{ style: { cursor: 'not-allowed' } }}
             />
     </Grid>
   </Grid>}
   
   
   {preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            Clinician Name <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            maxLength={200}
            aria-label="clinician name field"
            fullWidth
            size="small"
            minRows={2}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            value={preAuthFormData[0].ClinicianName}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.ApprovingManager !== undefined && preAuthFormData[0].ApprovingManager !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            ED/Admin <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            aria-label="ED admin field"
            fullWidth
            size="small"
            disabled={true}
            maxLength={200}
            minRows={2}
            value={preAuthFormData[0].ApprovingManager}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}
   
   
{preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            AVPO <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
  fullWidth
            aria-label="AVPO textField"
            size="small"
            maxLength={200}
            value={preAuthFormData[0].AVPO}
            minRows={2}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            inputProps={{ style: { cursor: 'not-allowed' } }}
            disabled={true}
          />
  </Grid>
</Grid>}

   
         
           </DialogContent>
   
   <Grid container sx={{justifyContent:'end'}}>
     <Grid>
     <DialogActions
                 style={{ justifyContent: "end", padding: "14px 16px" }}
               >
                 <Button
                   variant="contained"
                   sx={{textTransform:'none'}}
                   className='secondaryButton'
                   onClick={handleClose}
                 >
                   Close
                 </Button>
               </DialogActions>
     </Grid>
   </Grid>
           
         </Dialog>
      )}
     
    </>
    )
}

WoundVacAuthorizationForm.formName = "WoundVacAuthorizationForm";
export default WoundVacAuthorizationForm;