import React, { useEffect, useState } from "react";
import styles from "./OrdersProofDetail.module.scss";
import { Box, Dialog, DialogTitle, IconButton, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const OrdersProofDetail = ({ proofData, setProofData}) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setDialogOpen(true);
  }, [proofData]);

  const handleCloseDialogPatientsOrder = () => {
    setDialogOpen(false);
    setProofData(null);
  };
  const handlePrevImagePatientsOrder = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? proofData?.attachments?.length - 1 : prevIndex - 1
    );
  };

  const handleNextImagePatientsOrder = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === proofData?.attachments?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const viewOnMapPatientsOrder = () => {
    if (proofData.latitude && proofData.longitude) {
      const url = `https://www.google.com/maps?q=${proofData.latitude},${proofData.longitude}`;
      window.open(url, "_blank");
    }
  };

  return (
    <div className={styles.proofOfDelivery} data-testid="OrdersProofDetail">
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialogPatientsOrder}
          maxWidth="75%"
          fullWidth={false}
          sx={{ overflow: "hidden" }}
        >
          <div className={styles.flexContainer}>
            <DialogTitle className={styles.myStyle}>
              Proof of {proofData?.order_type}: Order #{proofData?.order_id}
            </DialogTitle>
            <IconButton
              sx={{ color: "rgba(0, 0, 0)" }}
              onClick={handleCloseDialogPatientsOrder}
            >
              <CloseIcon data-testid="CloseIcon" />
            </IconButton>
          </div>
          <DialogContent className={styles.paddingStyle}>
            <Box className={styles.dimensions}>
              <img
                src={proofData?.attachments?.[activeIndex]?.url}
                alt={`Image ${activeIndex + 1}`}
                className={styles.imageDimensions}
              />
            </Box>
          {proofData?.notes && <Box className={styles.customNotesStyle}>
            <span className={styles.textStyles}>Note(s): </span>
            <span className={styles.wordWrapContact}>
              {proofData?.notes}
            </span>
          </Box>}
            <Box className={styles.flexContainerProofOfDelivery}>
            <div className={styles.customStyles}>
            <span className={styles.textStyles}>Geo Verification:</span>
              {proofData?.geo_verified ? (
                <>
                  <span className={styles.greenText}>Pass</span>
                  <span className={styles.greenTextMargin}>
                    <CheckCircleIcon className={styles.fontSizeLarge} />
                  </span>
                </>
              ) : (
                <>
                  <span className={styles.redText}> Fail</span>
                  <span className={styles.redTextMargin}>
                    <ErrorIcon className={styles.textStyle}/>
                  </span>
                </>
              )}
            </div>
            <div className={styles.flexButton}>
            <span className={styles.dimGrayText}></span>
              <span className={styles.marginLeftAuto}>
                <Button
                  variant="contained"
                  size="small"
                  data-testid="ViewOnMap"
                  className='primaryButton'
                  onClick={viewOnMapPatientsOrder}>
                  <MapRoundedIcon className={styles.iconDimensions} />
                  View On Map
                </Button>
              </span>
            </div>
            </Box>
          </DialogContent>
       <div className={styles.alignment}>
       {activeIndex !== 0 && (
            <IconButton
            data-testid="PrevImagePatientsOrder"
              className={styles.absolutePositionLeft}
              onClick={handlePrevImagePatientsOrder}>
              <ChevronLeftIcon className={styles.arrowIcon} />
            </IconButton>
          )}
          {activeIndex !== proofData?.attachments?.length - 1 && (
            <IconButton
            data-testid="NextImagePatientsOrder"
              className={styles.absolutePositionRight}
              onClick={handleNextImagePatientsOrder}>
              <ChevronRightIcon className={styles.arrowIcon} />
            </IconButton>
          )}
       </div>
        </Dialog>
    </div>
  );
};

export default OrdersProofDetail;
