import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import tabStyles from './ProfileTab.module.scss';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={100}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        PaperProps={{
            style: {
                width: '77%',
            },
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '0px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '& .MuiMenuItem-root': {
            background: 'none !important',
            padding: '0px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            '& .Mui-selected': {
                background: 'none'
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

export const ProfileTab = ({ tabsData, selectedValue, setSelectedValue }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTabSelect = (index) => {
        setSelectedValue(index);
        handleClose();
    };

    return <div className={tabStyles.tabContainer}>
        <div className={tabStyles.selectedContainer} onClick={handleClick} data-testid='selectedContainerId'>
            <p><b>Current tab:</b></p>
            <p className='displayFlex alignItemCenter'>
                <p className='displayFlex alignItemCenter'>{tabsData[selectedValue].icon}</p>
                <p>{tabsData[selectedValue].label}</p>
            </p>
            <p className='displayFlex alignItemCenter'>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</p>
        </div>
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {tabsData.map((item, index) => {
                return (
                    <MenuItem
                        onClick={() => handleTabSelect(index)}
                        data-testid={`menuItem-${index}`}
                        selected={selectedValue === index}
                        key={index}
                        className={`${selectedValue == index && tabStyles.activeItem} ${tabStyles.menuItem}`}
                    >
                        <div
                            className={`
                                ${selectedValue == index && tabStyles.activeItemSpan} 
                                ${selectedValue == 0 && tabStyles.firstItemSelected} 
                                ${selectedValue == (tabsData?.length - 1) && tabStyles.lastItemSelected} 
                                ${tabStyles.itemSpan}
                                `}
                        ></div>
                        {item.icon}
                        {item.label}
                    </MenuItem>
                )
            })}
        </StyledMenu>
    </div >
}