
export const LoginApi = {
  initiate: "api/auth/initiate",
  oauthToken: "token",
  userConfig: "api/v1/users/configuration",
  logOut: "logout",
  userDevice:"api/v1/users/device",
};
export const DashboardApi = {
  systemAlertMsg: "api/v1/dashboard/{user_id}/systemalert",
  keyMetricsConfig: "api/v1/orders/metrics",
  Location: "api/v1/locations/autocomplete",
  RegionSearch: "api/v1/regions/autocomplete",
  exporttoCsv: "api/v1/orders/metrics/export",
  recentOrders: "api/v1/orders/recent"
};

export const PatientsApi = {
    addressBasedEquipments:"api/v1/patients/product-ordered-to-address",
    IDGReport: "api/v1/report/patients/idg/export",
    patientsListingName: "api/v1/patients/autocomplete",
    patientsListingSearchFilter: "api/v1/patients/search",
    patientsHospiceBranchSearch: "api/v1/locations/autocomplete",
    patientsEquipments: "api/v1/patients/equipments",
    patientsDetails: "api/v1/patients",
    patientsNotes: "api/v1/patients/{patient_id}/notes",
    patientsAddNotes:"api/v1/patients/notes",
    patientsAddresses: "api/v1/patients/{patient_id}/addresses",
    patientsProduct: "api/v1/patients/address-product",
    latestAddress: "api/v1/patients/{patient_id}/addresses/latest",
    addAddress: "api/v1/patients/{patient_id}/address",
    confirmationPatientDetails: "api/v1/patients/{patient_id}/equipmentforpickup",
    reasonForPickupOrder: "api/v1/orders/reasonforpickuporder",
    managePatientContact: "api/v1/patients/{patient_id}/manage-patient-contact"
};
export const CrossPlatformApi = {
    eToolsPatientAccess: "api/v1/CrossPlatform/GetEtoolsPatientAccess",
    eToolsMedications: "api/v1/CrossPlatform/GetEtoolsPatientMedications"
};
export const PatientsDetailsApi = {
  patientsOrderTab: "api/v1/patients"
}

export const UsersApi = {
  users: "api/v1/users",
  branchDetails: "api/v1/users/getuserbranchname",
  notifications: "api/v1/users/notificationpreference",
  getProvider:'api/v1/users/getproviders',
  getUserByLocation: "api/v1/users/getusersbylocation",
  getFirstTimeLogin: "api/v1/users/updatefirstloginstatus",
  stopImpersonation: "api/v1/users/stopimpersonation"
}

export const UserProfileApi = {
  profileImage: "api/v1/users/profileimage",
  userProfileImage: "api/v1/users/usersprofileimage",
  changePassword: "api/v1/users/changepassword",
}

export const UserContactsApi = {
  deleteContact: "api/v1/users/contacts",
  generateOtp: "api/v1/users/verification/generate",
  validateOtp: "api/v1/users/verification/validate",
  addContacts: "api/v1/users/contacts",
  editContacts: "api/v1/users/contacts",
}
export const AddPatientApi = {
  contactName: "api/v1/patients/contact/autocomplete",
  diagnosisCode: "api/v1/patients/diagnosiscode/autocomplete",
  nurseName: "api/v1/patients/searchnurse/autocomplete",
  duplicatePatient:"api/v1/patients/checkduplicate",
  facilityName:"api/v1/patients/facility/autocomplete"
}
export const ShareYourFeedbackApi = {
  shareYourFeedback: "api/v1/feedback"
}
export const GlobalSearchApi = {
  globalSearch:"api/v1/global-search"
}
export const RecurringOrderApi = {
  recurringOrderDetails:"api/v1/recurring-orders",
  recurringOrderEquipment: "api/v1/recurring-orders/{recurring_order_id}/product",
  recurringOrderSupplies: "api/v1/recurring-orders/{recurring_order_id}/supplies",
  recurringCancelOrder: "api/v1/recurringorders/{recurring_Order_id}/cancelrecurringorder",
  updateRecurringOrderSchedule : 'api/v1/recurring-orders/updaterecurringorderschedule',
  getSendTo:'api/v1/users/getusersbylocationandwarehouse'
}

export const OrdersApi = {
  ordersList:"api/v1/orders/client/search",
  ordersExport:"api/v1/orders/client/search/export",
  serviceEquipment:"api/v1/orders/serviceequipments",
  providers:"api/v1/orders/providers",
  settingForProvider:"api/v1/orders/settingsforprovider",
  productList:"api/v1/orders/product",
  serviceCallEquipment: "api/v1/orders/serviceequipments",
  reasonCode: "api/v1/orders/reasonfordelivery",
  priority: "api/v1/orders/priority/autocomplete",
  createOrder: "api/v1/orders/createorder",
  pickUpOrder:'api/v1/orders/pickuporder',
  globalOrder:"api/v1/orders/globalproducts",
  equipmentsList: "api/v1/editorder/equipments",
  equipmentSuggestions:"api/v1/orders/equipmentsuggestions",
  priorityDescriptions:"api/v1/orders/priorities-by-reason-code",
  medicalNecessity: "api/v1/orders/necessity",
  addressList: "api/v1/orders/{patient_id}/getpatientproductaddresslist",
  providerEquipmentPairing: "api/v1/orders/providerequipmentpairings",
  productListDekitted: "api/v1/orders/productlistdekitted",
  equipmentValidation:"api/v1/editorder/equipmentvalidation",
  editServiceOrder: "api/v1/orders/serviceorderequipment",
  updateServiceOrder: "api/v1/orders/updateserviceorderequipment",
  editDeliveryOrder: "api/v1/orders/editorder",
  editUserDetails: "api/v1/editorder/getuserorderdetails",
  updateEquipmentsDetails: "api/v1/orders/{order_id}/update",
  verifyAssetsTags: "api/v1/inventories/verifyassetnumber",
  getOrderItemsForAddress: "api/v1/editorder/getorderitemsforaddress",
  removeFromPickupOnly:"api/v1/editorder/removefrompickuponly",
  removePickup: "api/v1/editorder/removepickup",
  addPickupItems:"api/v1/editorder/addpickupitems",
  supplyCategory: "api/v1/supplyorder/supplycategories",
  supplyProducts: "api/v1/supplyorder/supplyproducts",
  supplyCheckout:"api/v1/punchout/checkout",
  addOrderCommunication:"api/v1/orders/addordercommunication",
  patientContactSubscription:"api/v1/patients/patientcontactsubscription"
}

export const RecurringOrdersApi = {
  recurringOrders:"api/v1/recurring-orders",
  recurringOrdersExport:"api/v1/recurring-orders/export",
}
export const OrdersComponentApi = {
  orderSearch: "api/v1/orders/client/search",
  orderTypeAutocomplete : "api/v1/orders/ordertype/autocomplete",
  orderStatusAutocomplete : "api/v1/orders/orderstatus/autocomplete",
  orderPriorityAutocomplete : "api/v1/orders/priority/autocomplete",
  orderDetails: "api/v1/orders",
  ordersNotes: "api/v1/orders/{order_id}/notes",
  ordersEquipment: "api/v1/orders/{order_id}/orderequipments/{user_id}",
  ordersCancelEquipment: "api/v1/orders/cancelequipment",
  orderProofOfDelivery : "api/v1/orders/{order_id}/proof-of-delivery",
  viewOtherOrders: "api/v1/orders/others",
  cancelOrder: "api/v1/orders/cancel",
  editOrderNotes: "api/v1/editorder/editordernotes",
  supplyDetails: "api/v1/supplyorder/{purchase_order_id}",
  otherSupplyDetails: "api/v1/supplyorder",
  supplyProofOfDelivery: "api/v1/supplyorder/{purchase_order_id}/proof-of-delivery",  
  supplyEquipment: "api/v1/supplyorder/supplyitems/:purchase_order_id/:branchId"
}
export const LocationStateApi = {
  states:"api/v1/locations/states",
  orderSettingsForBranch: "api/v1/locations/getordersettingsforbranch"
}

export const PreAuthComponentApi = {
  preAuthList: "api/v1",
  patientsAuthDetails: "api/v1/patient",
  preAuthForm:"api/v1/preauthorization/form"
}

export const GenerateReceiptApi = {
  generateReceipt:'api/v1/receipt/generatereceipt'
}

export const OrderApprovalsApi = {
  orderApprovalList : 'api/v1/orders/orderapprovallist',
  approvalStatus: "api/v1/orders/approvalstatus",
  reasonForDelivery: "api/v1/orders/reasondeliverystatus",
  orderApprovalExport: "api/v1/order-approval/export",
  orderApprovalDetails: "api/v1/order-approval",
  orderApprovalEquipment: "api/v1/order-approval/:order_id/approvalequipment/:branch_id",
  processOrderApproval: "api/v1/orderapproval/processorderapproval",
  dashboardOrderApproval: "api/v1/dashboard/pendingapproval",
  orderApprovalDrawerMenu: "api/v1/orderapproval/approvaltabvisible"
}
export const PrescriptionApi = {
  prescriptionListing: "api/v1/fileupload/documentlist",
  generatePrescriptionListing: "api/v1/fileupload/generatesignedurls",
  getFileUploadPermission: "api/v1/fileupload/getfileuploadpermission",
  activePrescription: "api/v1/patients/{patient_id}/activeprescription",
  addPrescriptionFile: "api/v1/fileupload/addfileinfo",
  viewFile:"api/v1/file-upload/{file_upload_id}/download",
  removeFile: "api/v1/file-upload/{file_upload_id}/remove",
  editFile: "api/v1/fileupload/updatefileinfo"
}

export const NotificationApi = {
  notificationListing: "api/v1/notification/notificationforuser",
  markAsReadNotification: 'api/v1/notification/markasreadonnotification',
  notification: 'api/v1/notification'
}

export const CustomFieldsApi = {
  customFields:"api/v1/customField"
}

export const Support = {
  contactUs: 'api/v1/support/contactus',
  caseReasonList:"api/v1/support/casereasonlist",
  addServiceIncident:"api/v1/support/addserviceincident",
  getActiveContractProviders:"api/v1/support/activecontractproviders",
  getItemRequestModel:"api/v1/support/itemdetail",
  selfServiceItemRequest:"api/v1/support/selfserviceitemform",
  saveItemRequest: "api/v1/support/saveitemrequest",
  hospices:"api/v1/support/hospices",
  companyInfo:"api/v1/support/company-info",
  branchList:"api/v1/support/branch-list",
  addUserAccessRequest:"api/v1/support/adduseraccessrequest",
  selfServiceForm:"api/v1/support/selfservice",
  validateUserEmail:"api/v1/support/validateuseremail",
  useraccesstemplate:"api/v1/support/useraccesstemplate",
  branchUsersList: "api/v1/support/branchuserlist",
  passHelpEmail: "api/v1/support/resetpasswordemail/{userId}",
  updateUserStatus: "api/v1/support/updateuserstatus",
  selfServiceServiceIncident:"api/v1/support/selfserviceserviceincident",
  downloadVideoUrl: "api/v1/fileupload/downloadvideourl"
}

export const OrderFeedbackApi = {
  orderFeedbackPageAuth :'api/v1/orders/{order_id}/orderfeedback',
  orderFeedbackPage :'api/v1/orders/orderfeedback/{order_feedback_id}',
  orderFeedbackPageAuthQuestions :'api/v1/feedback/{order_id}/feedbackquestion',  
  orderFeedbackPageQuestions:'api/v1/feedback/feedbackquestion/{order_feedback_id}',
}
export const PatientTransfer = {
  branchToTransfer: 'api/v1/patienttransfer/branchestotransfer/{companyId}/{locationId}/{patientId}',
  transferPatient: 'api/v1/patienttransfer/transferpatient',
  patientDeliveredEquipment: "api/v1/patienttransfer/patientdeliveredequipment"
}

export const CommunicationTab = {
  patientcontact:'api/v1/patients/patientcontact'
}

export const walkthrough = {
  saveWalkThrough:'api/v1/fileupload/savewalkthroughpage',
  walkThroughDetails: 'api/v1/fileupload/walkthroughdetail'
}

export const ReportsApi = {
  disasterPlanningReportList:"api/v1/report/disasterplanningreport",
  providerList:"api/v1/report/disasterplanning/providerlist",
  hospiceList:"api/v1/report/hospices/list",
  warehouseList:"api/v1/report/warehouses/list",
  disasterPlanningExport:"api/v1/report/disasterplanning/export",
  equipmentQuantityReport : 'api/v1/report/equipmentquantityutilization',
  equipmentQuantityExport : 'api/v1/report/equipmentquantityutilizationexport',
  costliestItem : "api/v1/report/costliestitems",
  costliestItemExport : "api/v1/report/costliestitemsexport",
  state: 'api/v1/locations/states',
  orderStatuses: 'api/v1/report/allorderstatuses',
  hospicesList: 'api/v1/report/hospices/list',
  advancedOrderProviderList: 'api/v1/report/advancedordersearch/providerlist',
  prescriptionReport: "api/v1/report/prescriptionreport",
  prescriptionExport: "api/v1/report/prescriptionreportexport",
  billingDetail: "api/v1/report/billingdetailreport",
  lastRunDateBillingDetail: "api/v1/report/getlastrundatebillingdetail",
  billingDetailExport: "api/v1/report/billingdetail/export",
  reportStatus: "api/v1/report/statuses",
  prescriptionReportBranch : "api/v1/report/prescriptionreports/branches/{hospiceId}",
  prescriptionReportData : "api/v1/report/prescriptionreport",
  advancedOrderSearch: "api/v1/report/advanceordersearch",
}
