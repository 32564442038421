import React from "react";
import { Typography, FormControl, Button, FormLabel, Box, Grid, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";

const SupportPreAuth = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();

    return (
        preAuthFormData &&
        <Dialog
            fullWidth
            maxWidth="md"
            open={true}
            onClose={handleClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>
                {t("PreAuthForm.supportHead")}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ margin: '10px 0px' }}>
                    <span className={styles.asterisk}>* </span> {t("PreAuthForm.requiredField")}
                </Typography>
                <Grid container>
                    <Grid xs={12} md={6} className={styles.spacingTop} sx={{ marginTop: '0px !important' }}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>
                                {t("PreAuthForm.branchCode")} <span className={styles.asterisk}> *</span>
                            </Typography>
                            <TextField
                                margin="normal"
                                fullWidth
                                size="small"
                                autoFocus
                                variant="outlined"
                                id="name"
                                type="text"
                                value={preAuthFormData[0]?.BranchCode}
                                sx={{
                                    marginTop: '5px', borderRadius: "8px", backgroundColor: 'var(--color-disableField)', '& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important', width: "220px",
                                    },
                                }}
                                disabled={true}
                                inputProps={{ style: { cursor: 'not-allowed' } }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                {preAuthFormData[0]?.IsStageIIPressureAreasNotImproved !== "" && preAuthFormData[0]?.IsStageIIPressureAreasNotImproved !== undefined &&
                    <Grid container>
                        <Grid xs={12} className={styles.spacingTop}>
                            <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                    <div>1.&nbsp;</div>
                                    <div>{t("PreAuthForm.isStageIIPressureAreasNotImproved").replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase())}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                <RadioGroup
                                    className='paddingLeftFifteenpx'
                                    value={preAuthFormData[0].IsStageIIPressureAreasNotImproved}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group">
                                    <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                </RadioGroup>
                                <div className={styles.spacingTop}>
                                    <FormLabel className={`${styles.labelText} displayFlex`} sx={{ paddingLeft: '20px' }} id="demo-row-radio-buttons-group-label">
                                        <div>a.&nbsp;</div>
                                        <div>{t("PreAuthForm.comprehensiveUlcerTreatment").replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase())}</div>
                                    </FormLabel>
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>}

                {preAuthFormData[0]?.IsUlcerTreatmentWeeklyAssessment !== "" && preAuthFormData[0]?.IsUlcerTreatmentWeeklyAssessment !== undefined && <Grid container sx={{ paddingLeft: '37px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex `} id="demo-row-radio-buttons-group-label">
                                <div>i.&nbsp;</div>
                                <div>{t("PreAuthForm.isUlcerTreatmentWeeklyAssessment")}<span className="asteriskSignColor">&nbsp;*</span>
                                </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsUlcerTreatmentWeeklyAssessment}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsUlcerTreatmentTurningPositioning !== "" && preAuthFormData[0]?.IsUlcerTreatmentTurningPositioning !== undefined && <Grid container sx={{ paddingLeft: '37px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>ii.&nbsp;</div>
                                <div>{t("PreAuthForm.isUlcerTreatmentTurningPositioning")}<span className="asteriskSignColor"> *</span>
                                </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsUlcerTreatmentTurningPositioning}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsUlcerTreatmentMoistureIncontinence !== "" && preAuthFormData[0]?.IsUlcerTreatmentMoistureIncontinence !== undefined && <Grid container sx={{ paddingLeft: '37px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>iii.&nbsp;</div>
                                <div>{t("PreAuthForm.isUlcerTreatmentWoundCare")}<span className="asteriskSignColor"> *</span> </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsUlcerTreatmentMoistureIncontinence}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsUlcerTreatmentMoistureIncontinence !== "" && preAuthFormData[0]?.IsUlcerTreatmentMoistureIncontinence !== undefined && <Grid container sx={{ paddingLeft: '37px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>iv.&nbsp;</div>
                                <div>{t("PreAuthForm.isUlcerTreatmentMoistureIncontinence")}<span className="asteriskSignColor"> *</span>
                                </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsUlcerTreatmentMoistureIncontinence}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsUlcerTreatmentNutritionalAssessment !== "" && preAuthFormData[0]?.IsUlcerTreatmentNutritionalAssessment !== undefined && <Grid container sx={{ paddingLeft: '37px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>v.&nbsp;</div>
                                <div>{t("PreAuthForm.isUlcerTreatmentNutritionalAssessment")}<span className="asteriskSignColor"> *</span>
                                </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsUlcerTreatmentNutritionalAssessment}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.WoundLocationAndDetails && <Grid container sx={{ paddingLeft: '20px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={`${styles.labelText} displayFlex`}>
                            <div>b.&nbsp;</div>
                            <div>{t("PreAuthForm.woundLocationAndDetails")}<span className="asteriskSignColor"> *</span>
                            </div>
                        </Typography>
                        <TextField
                            fullWidth
                            minRows={2}
                            size="small"
                            value={preAuthFormData[0].WoundLocationAndDetails}
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)', borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsStageIIIorIVPressureUlcers !== "" && preAuthFormData[0]?.IsStageIIIorIVPressureUlcers !== undefined && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>2.&nbsp;</div>
                                <div> {t("PreAuthForm.isStageIIIorIVPressureUlcers").replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase())}
                                    <span className="asteriskSignColor"> *</span>
                                </div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsStageIIIorIVPressureUlcers}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.StageIIIorIVWoundDetails && <Grid container sx={{ paddingLeft: '20px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={`${styles.labelText} displayFlex`}>
                            <div>a.&nbsp;</div>
                            <div> {t("PreAuthForm.stageIIIorIVWoundDetails")}<span className="asteriskSignColor"> *</span></div>
                        </Typography>
                        <TextField
                            fullWidth
                            minRows={2}
                            size="small"
                            value={preAuthFormData[0].StageIIIorIVWoundDetails}
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)', borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsRecentSkinGraftOrFlap !== "" && preAuthFormData[0]?.IsRecentSkinGraftOrFlap !== undefined && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>3.&nbsp;</div>
                                <div>{t("PreAuthForm.isRecentSkinGraftOrFlap").replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase())}
                                    <span className="asteriskSignColor">&nbsp;*</span></div>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsRecentSkinGraftOrFlap}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.SurgeryDischargeDate && <Grid container sx={{ paddingLeft: '10px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={`${styles.labelText} displayFlex`}>
                            <div>a.&nbsp;</div>
                            <div> {t("PreAuthForm.surgeryDischargeDate")}<span className="asteriskSignColor"> *</span></div>
                        </Typography>
                        <TextField
                            fullWidth
                            minRows={2}
                            size="small"
                            value={preAuthFormData[0].SurgeryDischargeDate}
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)',
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                <div>4.&nbsp;</div>
                                <div>{t('PreAuthForm.physicianOrder')}<span className={styles.asterisk}>&nbsp;*</span></div>
                            </FormLabel>
                            <Box component="span" sx={{ fontWeight: 'bold', fontStyle: "italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsPhysicianOrder}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.IsFacilityRequested !== "" && preAuthFormData[0]?.IsFacilityRequested !== undefined && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <FormControl className={styles.spacingRemoveTop}>
                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                5. {t('PreAuthForm.facilityRequested')}<span className={styles.asterisk}> *</span>
                            </FormLabel>
                            <RadioGroup
                                className='paddingLeftFifteenpx'
                                value={preAuthFormData[0].IsFacilityRequested}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.FacilityRequestingName && <Grid container sx={{ paddingLeft: '20px' }}>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={styles.labelText}>
                            {t('PreAuthForm.nameOfFacility')}<span className="asteriskSignColor"> *</span>
                        </Typography>
                        <TextField
                            minRows={2}
                            aria-label="requesting custom text"
                            size="small"
                            fullWidth
                            value={preAuthFormData[0].FacilityRequestingName}
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)',borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.ClinicianName && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={styles.labelText}>
                            {t('PreAuthForm.clinicalName')} <span className={styles.asterisk}> *</span>
                        </Typography>
                        <TextField
                            aria-label="clinic text field"
                            value={preAuthFormData[0].ClinicianName}
                            fullWidth
                            minRows={2}
                            size="small"
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)', borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={styles.labelText}>
                            {t("PreAuthForm.edAdmin")} <span className={styles.asterisk}> *</span>
                        </Typography>
                        <TextField
                            fullWidth
                            aria-label="Admin field"
                            minRows={2}
                            value={preAuthFormData[0].AdministratorOrED}
                            size="small"
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)', borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}

                {preAuthFormData[0]?.AVPO && <Grid container>
                    <Grid xs={12} className={styles.spacingTop}>
                        <Typography className={styles.labelText}>
                            {t("PreAuthForm.avpo")} <span className={styles.asterisk}> *</span>
                        </Typography>
                        <TextField
                            fullWidth
                            aria-label="avpo field"
                            minRows={2}
                            value={preAuthFormData[0].AVPO}
                            size="small"
                            sx={{ marginTop: '5px', backgroundColor: 'var(--color-disableField)', borderRadius:"8px",
                                '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                    }, 
                             }}
                            disabled={true}
                            inputProps={{ style: { cursor: 'not-allowed' } }}
                        />
                    </Grid>
                </Grid>}
            </DialogContent>

            <DialogActions style={{ justifyContent: "end", padding: "14px 16px" }}>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    className='secondaryButton'
                >
                    {t('NewOrder.close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SupportPreAuth.formName = "SupportPreAuth";
export default SupportPreAuth;