import React from 'react';
import styles from './OrderTypeText.module.scss';
import { commonConstants } from '../../constants';

const OrderTypeText = ({ orderType, orderStatus, deliverOn, deliverBy, pickedUpOn, pickupBy, serviceOn, serviceBy, mixOrderConfiguration }) => {
  const renderDateText = (label, date) => (
    <>
      <span className={styles.customLabelText}>{label}</span>
      <span className={styles.customContentText}>{date}</span>
    </>
  );

  const isNotDefaultDate = (date) => date && date !== commonConstants.defaultDateTime;

  const renderDeliveryText = () => {
    const isMixOrder = mixOrderConfiguration == "D";
    if ((orderStatus === "Delivered" || orderStatus === "Complete") && isNotDefaultDate(deliverOn)) {
      return renderDateText(isMixOrder ? "Delivered on:" : "Completed on:", deliverOn);
    } else if (isNotDefaultDate(deliverBy)) {
      return renderDateText(isMixOrder ? "Deliver by:" : "Complete by:", deliverBy);
    }
    return null;
  };

  const renderPickupText = () => {
    const isMixOrder = mixOrderConfiguration == "P";
    if ((orderStatus === "Picked Up" || orderStatus === "Complete") && isNotDefaultDate(pickedUpOn)) {
      return renderDateText(isMixOrder ? "Picked up on:" :  "Completed on:", pickedUpOn);
    } else if (isNotDefaultDate(pickupBy)) {
      return renderDateText(isMixOrder ? "Pickup by:" : "Complete by:", pickupBy);
    }
    return null;
  };

  const renderServiceRequestText = () => {
    const isMixOrder = mixOrderConfiguration == "SR";
    if (orderStatus == "Delivered" && isNotDefaultDate(serviceOn)) {
      return renderDateText(isMixOrder ? "Serviced on:" : "Completed on:", serviceOn);
    } else if (isNotDefaultDate(serviceBy)) {
      return renderDateText(isMixOrder ? "Service by:": "Complete by:", serviceBy);
    }
    return null;
  };

  const renderServiceText = () => {
    const isMixOrder = mixOrderConfiguration == "SR";
    if (["Serviced", "Complete", "Delivered"].includes(orderStatus) && isNotDefaultDate(serviceOn)) {
      return renderDateText(isMixOrder ? "Serviced on:" : "Completed on:", serviceOn);
    } else if (isNotDefaultDate(serviceBy)) {
      return renderDateText(isMixOrder ? "Service by:": "Complete by:", serviceBy);
    }
    return null;
  };

  if (orderStatus === "Canceled") return null;

  switch (orderType) {
    case "Delivery":
    case "Supplies":
    case "Supply":
      return renderDeliveryText();
    case "Pick Up":
    case "Pickup":
      return renderPickupText();
    case "Pick up all":
    case "Pickup all":
      return isNotDefaultDate(pickupBy) ? renderDateText("Pickup by:", pickupBy) : null;
    case "Service Request":
      return renderServiceRequestText();
    case "Service":
      return renderServiceText();
    default:
      return null;
  }
};

export default OrderTypeText;
