import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import PhoneIcon from "@mui/icons-material/Phone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Button, Card, CardContent, Chip, Grid,Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EntityTypeForFollow, FacilityTypeEnum, OrderReasonEnum, OrderReasonPickUpEnum, PhoneEnum, RelationShipEnum, deliveryOrderType, statusEnum } from "../../../common/enums";
import CancelOrder from "../../../components/CancelOrder/CancelOrder";
import { errorToast, successToast } from "../../../components/ToastMessage/ToastMessage";
import FollowButton from "../../../components/followButton/followButton";
import withTitle from "../../../components/withTitle/withTitle";
import { GenerateReceiptApi, OrderFeedbackApi, OrdersComponentApi } from "../../../constants/ApiConstants";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";
import { HTTPMethod, callApi as OrderDetailRequest } from "../../../services/HttpService/HttpService";
import styles from "./OrdersDetails.module.scss";
import loader from "../../../../src/assets/loader.gif";
import NoData from "../../../components/noData/noData";
import StatusColor from "../../../common/Utils/StatusColor/StatusColor";
import ReasonCode from "../../../common/Utils/ReasonCode/ReasonCode";
import Demographics from "../../../components/Demographics/Demographics";
import { useSelector } from "react-redux";
import PriorityIcon from "../../../common/Utils/PriorityIcon/PriorityIcon/PriorityIcon";
import ETA from "../../../components/ETA/ETA";
import MapLocationButton from "../../../components/MapLocationButton/MapLocationButton";
import { commonConstants } from "../../../common/constants";
import OrderTypeText from "../../../common/Utils/OrderTypeText/OrderTypeText";
import { handlePrimaryContact } from "../../../common/Utils/CommonFunctions/CommonFunctions";
import ProofOrderDetailCards from "../../../components/ProofOrderDetailCards/ProofOrderDetailCards";
import OrderType from "../../../components/OrderType/OrderType";
import { ItemLabel } from "../../../components/DashboardCard/Card";

export const getOrderFeedback = async () => {
  const orderId = location.pathname.split("/").pop();
    const orderFeedbackResponse = await OrderDetailRequest(HTTPMethod.Get, OrderFeedbackApi.orderFeedbackPageAuth.replace("{order_id}",orderId))
    if (orderFeedbackResponse && orderFeedbackResponse.status_code == 200) {
      return orderFeedbackResponse.data
    }
}

export const checkOrderFeedbackExpired = (createdOn,orderFeedBackExpirationDays) => {
  const createdDate = new Date(createdOn);
  const expiredDate = new Date(createdDate.setDate(createdDate.getDate() + orderFeedBackExpirationDays));
  const currentDate = new Date();
  return expiredDate.getTime() >= currentDate.getTime();
}

const OrdersDetails = ({ setOrder, setOrderPatientId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isFollowButton, setFollowButton] = useState(false);
  const [isFollowFollowing, setSelectedFollowFollowing] = useState(false);
  const [orderDetailsData, setOrdersDetailsData] = useState(null);
  const [patientContacts, setPatientContacts] = useState([])
  const [proofOfDelivery, setProofOfDelivery] = useState(null);
  const navigate = useNavigate();
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false);
  const [alertEquipmentName, setAlertEquipmentName] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderFeedbackData, setOrderFeedbackData] = useState();
  const orderFeedBackExpirationDays = useSelector((state) => state.authState.userData?.data?.application_settings?.order_feed_back_expiration_days)


  const handleRecurringDetailPage = (recurring_order_id) => {
    navigate("/recurring-orders/recurring-order-details/" + recurring_order_id);
  };
 
  const handleOrdersDetails = async () => {
    setOrder(null);
    setOrdersDetailsData(null);
    setIsLoading(true);
    const orderId = location.pathname.split("/").pop();
    const response = await OrderDetailRequest(
      HTTPMethod.Get, `${OrdersComponentApi.orderDetails}/${orderId}?cwp_order_detail=true`
    );
    if (response && response.status_code == 200 && response.data) {
      setOrdersDetailsData(response.data);

      const data = handlePrimaryContact(response.data?.patient_contacts);
      setPatientContacts([...data]);
     
      setOrder(response.data?.orders?.order_id);
      setOrderPatientId(response.data?.patient_info?.patient_id)
      setSelectedFollowFollowing(response?.data?.orders?.follow)
      setIsLoading(false);
      if (
        response.data?.orders?.order_status_id.includes(deliveryOrderType.Delivery) ||
        response.data?.orders?.order_status_id.includes(deliveryOrderType.PickUp) ||
        response.data?.order_type?.toLowerCase().includes("service")
      )
        proofOfDeliveryDetails();
      if (
        response.data?.orders?.order_complete_status !== "Complete" &&
        response.data?.orders?.order_complete_status !== "Delivered" &&
        response.data?.orders?.order_complete_status !== "Canceled"
      )
        setFollowButton(true);
    }
  };

  const proofOfDeliveryDetails = async () => {
    const orderId = location.pathname.split("/").pop();
    const response = await OrderDetailRequest(
      HTTPMethod.Get,
      OrdersComponentApi.orderProofOfDelivery.replace("{order_id}", orderId)
    );
    if (response && response.status_code == 200 && response.data) {
      setProofOfDelivery(response.data);
    }
  };
  useEffect(() => {
    handleOrdersDetails();
  }, [location.pathname]);

  const handlePdfGenerate = async () => {
    setIsLoading(true)
    const orderId = location.pathname.split("/").pop();
    const pdfAPIResponse = await OrderDetailRequest(HTTPMethod.Get, GenerateReceiptApi.generateReceipt + "?order_id=" + orderId)
    if (pdfAPIResponse && pdfAPIResponse.status_code == 200) {
        if (pdfAPIResponse.data) {
          setIsLoading(false)
          const pdfCreateUrl = pdfAPIResponse.data.receipt_order_url;
          window.open(pdfCreateUrl)
        }
    }
  }


  const handleEditOrder = () => {
      navigate(`/orders/edit-order/${orderDetailsData?.orders?.order_id}`);
  };

  const getFeedbackData = async () => {
    const feedbackResponse = await getOrderFeedback();
    setOrderFeedbackData(feedbackResponse);
  }

  useEffect(()=>{
    getFeedbackData();
  },[])
  
  const handleCancelOrder = async () => {
    setIsCancelOrderPopupOpen(false)
    const requestBody = {
      order_id: alertEquipmentName,
      cancel_reason: cancelReason
    }
      const response = await OrderDetailRequest(
        HTTPMethod.Put,
        OrdersComponentApi.cancelOrder,
        requestBody,
        "event-cancel-order"
      );
      setCancelReason("");
      if (response && response.status_code == 200) {
        const message = response?.return_message[0];
        successToast(message);
        handleOrdersDetails();
      }
      else {
        errorToast("There was a problem. Please contact technical support.");
      }
  }
  
  return (
    <div className={styles.customDiv}>
    {orderDetailsData ?
     <div>
        <Card variant="outlined" sx={{ textAlign: "left" }}>
          <CardContent>
            <Typography>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    display: "flex",
                    paddingBottom: "5px",
                    fontSize: "25px",
                    fontWeight: 600,
                    marginBottom: "8px",
                  }}
                >
                  <p className={styles.marginClass}>
                    {orderDetailsData?.orders?.order_id}
                  </p>
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  display="flex"
                  marginBottom={"10px"}
                  alignItems={{ sm: "flex-start" }}
                  justifyContent={{
                    xs: "flex-start",
                    sm: "space-between",
                  }}
                >
                  {!(
                    orderDetailsData?.orders?.order_complete_status ==
                      "Canceled" ||
                    orderDetailsData?.orders?.order_complete_status ==
                      "Complete" ||
                    orderDetailsData?.orders?.order_complete_status ==
                      "Delivered" ||
                    orderDetailsData?.orders?.order_complete_status ==
                      "Picked Up"
                  ) &&
                    isFollowButton && (
                      <FollowButton
                        toggleValue={isFollowFollowing}
                        toggleSetValue={setSelectedFollowFollowing}
                        followPayload = {{
                          entityId:orderDetailsData?.orders?.order_id,
                          userId:[],
                          entityType:EntityTypeForFollow.Order,
                        }}
                      />
                    )}
                </Stack>
              </Box>
              <Box style={{ position: "relative" }}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ sm: "flex-start" }}
                  justifyContent={{
                    xs: "flex-start",
                    sm: "space-between",
                  }}
                >
                  <div className={styles.chipEta}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems:"center",
                      fontSize: "25px",
                      fontWeight: 300,
                    }}
                  >
                    {orderDetailsData?.orders?.order_complete_status && (
                      <Chip
                        label={
                          orderDetailsData?.orders?.order_complete_status ==
                            "Canceled" &&
                          orderDetailsData?.orders?.order_type == "Delivery"
                            ? "Delivery Canceled"
                            : orderDetailsData?.orders?.order_complete_status ==
                                "Canceled" &&
                              orderDetailsData?.orders?.order_type == "Pickup"
                            ? "Pickup Canceled"
                            : orderDetailsData?.orders?.order_complete_status
                        }
                        size="small"
                        alignItems={{ sm: "flex-start" }}
                        sx={{
                          color: "#fff",
                          borderRadius: "6px",
                          fontWeight: 800,
                          fontSize: "13px",
                          backgroundColor: StatusColor(
                            orderDetailsData?.orders?.order_complete_status
                          ),
                        }}
                      ></Chip>
                    )}
                   
                  </Typography>
                  {(orderDetailsData?.orders?.order_complete_status === statusEnum["En-Route"] || orderDetailsData?.orders?.order_complete_status === statusEnum["On my way"] || orderDetailsData?.orders?.order_complete_status === statusEnum["Picking Up"]) && (
                  <ETA className={styles.etaIcon} arrivalTime={orderDetailsData?.orders?.arrival_time} formattedArrivalTime={orderDetailsData?.orders?.arrival_time_formatted} manualEta={orderDetailsData?.formatted_eta} orderStatus={orderDetailsData?.orders?.order_complete_status} isDelayed={orderDetailsData?.orders?.is_eta_delayed} etaWindow={orderDetailsData?.orders?.eta_window_excepted_time}/>
                      )}
                    </div>
                  <Stack
                    direction="row"
                    display="flex"
                    marginBottom={"10px"}
                    marginTop={["8px","0px"]}
                    alignItems={{ sm: "flex-start" }}
                    justifyContent={{
                      xs: "flex-start",
                      sm: "space-between",
                    }}
                  >
                    {orderDetailsData.order_status !== "Canceled" && (
                      <>
                        {orderDetailsData.is_view_receipt_link_visible ===
                          true &&
                          orderDetailsData.receipt_link_name ===
                            "Work order" && (
                            <Tooltip
                              title={
                                <span className={styles.tooltipStyling}>
                                  Work Order
                                </span>
                              }
                              onClick={handlePdfGenerate} data-testid="pdfGenerateButton"
                            >
                              <Button className='blueIconButton'>
                                <TaskAltIcon className={styles.customSizeImage} />
                              </Button>
                            </Tooltip>
                          )}

                        {orderDetailsData.is_view_receipt_link_visible ===
                          true &&
                          orderDetailsData.receipt_link_name ===
                            "View Receipt" && (
                            <Tooltip
                              title={
                                <span className={styles.tooltipStyling}>
                                  View Receipt
                                </span>
                              }
                              onClick={handlePdfGenerate}
                            >
                              <Button className='blueIconButton'>
                                <ReceiptLongIcon
                                  className={styles.customSizeImage}
                                />
                              </Button>
                            </Tooltip>
                          )}
                      </>
                    )}
                    {orderDetailsData?.orders?.order_id &&
                      (orderDetailsData?.orders?.order_complete_status == "Received" ) && (
                        <Tooltip
                          title={
                            <span className={styles.tooltipStyling}>Edit Order</span>
                          }
                        >
                          <Button
                            className="grayIconButton marginLeftEight"
                            variant="contained"
                            size="small"
                            data-testid="EditOrder"
                            onClick={handleEditOrder}
                          >
                            <EditIcon className={styles.customSizeImage} />
                          </Button>
                        </Tooltip>
                      )}
                    {orderDetailsData?.orders?.order_id &&
                      (orderDetailsData?.orders?.order_complete_status == "Received" ) && (
                        <Tooltip
                          title={
                            <span className={styles.tooltipStyling}>
                              Cancel Order
                            </span>
                          }
                        >
                          <Button
                            className="redIconButton marginLeftEight"
                            onClick={(e) => {
                              setIsCancelOrderPopupOpen(true);
                              setAlertEquipmentName(
                                orderDetailsData?.orders?.order_id
                              );
                              e.stopPropagation();
                            }}
                            variant="contained"
                            size="small"
                          >
                            <ClearIcon className={styles.customSizeImage} data-testid="ClearIcon" />
                          </Button>
                        </Tooltip>
                      )}

                      {(orderDetailsData?.orders?.order_complete_status === "En-Route" || orderDetailsData?.orders?.order_complete_status === "On my way" || orderDetailsData?.orders?.order_complete_status === "Picking Up")  && (
                        
                    <div className='marginLeftEight' data-testid="EtaMapBtn" onClick={(event) => event.preventDefault()}>
                        
                      <MapLocationButton orderStatus={orderDetailsData?.orders?.order_complete_status} eta={orderDetailsData?.formatted_eta} orderId={orderDetailsData?.orders?.order_id} 
                           latitude={orderDetailsData?.address?.latitude} longitude={orderDetailsData?.address?.longitude} addressLine1={orderDetailsData?.address?.address_line1} addressLine2={orderDetailsData?.address?.address_line2} city={orderDetailsData?.address?.city} state={orderDetailsData?.address?.state} zipcode={orderDetailsData?.address?.zip_code} country={orderDetailsData?.address?.country} arrivalTime={orderDetailsData?.orders?.arrival_time} formattedArrivalTime={orderDetailsData?.orders?.arrival_time_formatted} isDelayed={orderDetailsData?.orders?.is_eta_delayed} etaWindow={orderDetailsData?.orders?.eta_window_excepted_time}
                       />
                  </div>
                  )}
                  </Stack>
                </Box>

                <Grid container alignItems="center" marginTop={"10px"}>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <Typography
                        className={styles.branchText}
                        style={{ marginBottom: "10px" }}
                      >
                        {
                          orderDetailsData?.hospice_branch_contact
                            ?.hospice_branch_name
                        }
                      </Typography>
                        <OrderTypeText
                          orderType={orderDetailsData?.orders?.order_type}
                          orderStatus={orderDetailsData?.orders?.order_complete_status}
                          deliverOn={orderDetailsData?.formatted_delivered_on}
                          deliverBy={orderDetailsData?.deliver_by_formatted}
                          pickedUpOn={orderDetailsData?.delivered_on_formatted}
                          pickupBy={orderDetailsData.pickup_by_formatted}
                          serviceOn={orderDetailsData?.formatted_delivered_on}
                          serviceBy={orderDetailsData?.service_by_formatted}
                          mixOrderConfiguration={orderDetailsData?.orders?.mix_order_configuration}
                        />
                    </div>
                    <div>
                      <ItemLabel label="Provider" text={`${orderDetailsData?.provider_contacts?.provider_name} (${orderDetailsData?.provider_contacts?.provider_location})`} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className={styles.gridAlignment}>
                    <div>{PriorityIcon(orderDetailsData?.priority_name)}</div>
                     {orderDetailsData?.reason_code && <div style={{ marginLeft: "7px" }}>
                        {ReasonCode(orderDetailsData?.reason_code, orderDetailsData?.orders?.order_type)}
                      </div>}
                      <div style={{ marginLeft: "7px" }}>
                        <OrderType mixOrderConfiguration={orderDetailsData?.orders?.mix_order_configuration}/>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          </CardContent>
        </Card>
          {orderDetailsData?.patient_info && (
            <Card
              className={styles.customBox2}
              variant="outlined"
              sx={{ textAlign: "left", marginTop: "30px" }} >
              <CardContent>
                <Demographics patientId={orderDetailsData?.patient_info?.patient_id} patientInformation={orderDetailsData?.patient_info} hasOtherOrder={orderDetailsData.has_other_order} orderId={orderDetailsData?.orders?.order_id}
                  dxCode={orderDetailsData?.dx_code} authCode={orderDetailsData?.auth_code} branchId={orderDetailsData?.patient_info.branch_id}/>
              </CardContent>
            </Card>
          )}

      <Card variant="outlined" className={styles.customBox2}>
        <Box className={styles.scopedClassName}>
          <div
            className={styles.scopedClassHeading}
            sx={{ marginBottom: "5px" }}
          >
            {t("PatientsDetails.contacts")}
          </div>
        </Box>
        <CardContent sx={{ paddingTop: "24px" }}>
          <Grid container style={{ width: "100%" }}>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {orderDetailsData?.patient_contacts?.length > 0 &&
                patientContacts.map((contactData, index) => (
                  <div key={contactData.phone} >
                    <Grid item>
                      <Typography className={styles.marginNurse}>
                        <span className={styles.customText1}>
                          {contactData?.contact_name && (
                            <span>
                              {contactData.contact_name}&nbsp;(
                              {RelationShipEnum[contactData?.relationship]})
                              {contactData.is_primary_contact && (
                                <Chip
                                  label="Primary"
                                  size="small"
                                  sx={{
                                    borderRadius: "10px",
                                    backgroundColor: "#619633",
                                    margin: "0px 0px 0px 5px",
                                    color: "#fff"
                                  }}
                                  className={`${styles.myCustomClass2} ${styles.myCustomChip}`}
                                />
                              )}
                            </span>
                          )}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: "100%" }}>
                      <Typography>
                        <span className={styles.customText3}>
                          {contactData.phone.replace(
                            /(\d{3})-?(\d{3})-?(\d{4})/,
                            "($1) $2-$3"
                          )}
                          {contactData.phone_type &&
                          contactData.phone_type == 1 ? (
                            <>
                              <StayCurrentPortraitIcon
                                className={styles.smallMargin}
                              />
                            </>
                          ) : (
                            <>
                              <PhoneIcon className={styles.smallMargin} />
                            </>
                          )}
                          <span className={styles.mobileText}>
                            (
                            {contactData.phone_type &&
                            contactData.phone_type != 0
                              ? PhoneEnum[contactData.phone_type]
                              : PhoneEnum[2]}
                            )
                          </span>
                        </span>
                      </Typography>
                      {contactData.extension && (
                        <div className={styles.customFlexContact}>
                          <Typography>
                            <span className={styles.customText4}>Ext: </span>
                          </Typography>
                          <span
                            style={{ paddingLeft: "2px" }}
                            className={styles.customText6}
                          >
                            {" "}
                            {contactData.extension}
                          </span>{" "}
                        </div>
                      )}
                      {contactData.email && (
                        <div className={styles.customFlexContact}>
                          <Typography>
                            <span className={styles.customText4}>Email: </span>
                          </Typography>
                          <span
                            style={{ paddingLeft: "2px" }}
                            className={styles.customText6}
                          >
                            {contactData.email}{" "}
                          </span>
                        </div>
                      )}
                      {contactData.notes && (
                        <Typography
                          className={styles.scopedClassNameLine}
                          sx={{
                            marginBottom: "5px",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <span className={styles.customText4}>Notes: </span>
                          <span className={styles.wordWrapContact}>
                            {contactData.notes}
                          </span>
                        </Typography>
                      )}
                    </Grid>

                    {orderDetailsData?.patient_contacts?.length > 1 &&
                      index !==
                        orderDetailsData?.patient_contacts?.length - 1 && (
                          <hr className='hrMargin'/>
                      )}
                  </div>
                ))}
            </div>
            <Grid item style={{ width: "100%" }}>
              {orderDetailsData?.hospice_branch_contact?.hospice_name && orderDetailsData?.patient_contacts?.length > 0 && (
                <hr className='hrMargin'/>
              )}
            </Grid>
            {orderDetailsData?.hospice_branch_contact?.hospice_name && (
              <Grid item>
                <Typography>
                  <span className={styles.customText1}>
                    {orderDetailsData?.hospice_branch_contact?.hospice_name}
                    {orderDetailsData?.hospice_branch_contact
                      ?.hospice_branch_name && (
                      <span>
                        &nbsp;(
                        {
                          orderDetailsData?.hospice_branch_contact
                            ?.hospice_branch_name
                        }
                        )
                      </span>
                    )}
                    <Chip
                      label={LineOfBusinessEnum.hospiceToPace}
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "var(--color-grey)",
                        margin: "0px 0px 0px 5px",
                        color: "#fff",
                      }}
                      className={`${styles.myCustomClass2} ${styles.myCustomGreyChip}`}
                    />
                  </span>
                </Typography>
              </Grid>
            )}
            <Grid item style={{ width: "100%" }}>
              {orderDetailsData?.hospice_branch_contact?.phone_number && (
                <Typography>
                  <span className={styles.customText3}>
                    {orderDetailsData?.hospice_branch_contact?.phone_number.replace(
                      /(\d{3})-(\d{3})-(\d{4})/,
                      "($1) $2-$3"
                    )}
                    <PhoneIcon className={styles.smallMargin} />
                    <span className={styles.mobileText}>(Landline)</span>
                    {orderDetailsData?.hospice_branch_contact?.extension && (
                      <span className={styles.mobileText}>
                        ({orderDetailsData?.hospice_branch_contact?.extension})
                      </span>
                    )}
                  </span>
                </Typography>
              )}
              {orderDetailsData?.hospice_branch_contact?.after_hours_phone && (
                <span className={styles.customText3}>
                  <span className={styles.customText1}>
                    After hours phone:&nbsp;
                  </span>
                  <span className={styles.customText3}>
                    {orderDetailsData?.hospice_branch_contact?.after_hours_phone.replace(
                      /(\d{3})-(\d{3})-(\d{4})/,
                      "($1) $2-$3"
                    )}
                    <PhoneIcon className={styles.smallMargin} />
                    <span className={styles.mobileText}>(Landline)</span>
                    {orderDetailsData?.hospice_branch_contact?.extension && (
                      <span className={styles.mobileText}>
                        ({orderDetailsData?.hospice_branch_contact?.extension})
                      </span>
                    )}
                  </span>
                </span>
              )}
            </Grid>
            <Grid item style={{ width: "100%" }}>
              {orderDetailsData?.provider_contacts?.provider_name && (
                <hr className='hrMargin'/>
              )}
            </Grid>
            {orderDetailsData?.provider_contacts?.provider_name && (
              <Grid item>
                <Typography>
                  <span className={styles.customText1}>
                    {orderDetailsData?.provider_contacts?.provider_name}
                    {orderDetailsData?.provider_contacts?.provider_location && (
                      <span>
                        &nbsp;(
                        {orderDetailsData?.provider_contacts?.provider_location}
                        )
                      </span>
                    )}
                    <Chip
                      label="Provider"
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "var(--color-grey)",
                        margin: "0px 0px 0px 5px",
                        color: "#fff",
                      }}
                      className={`${styles.myCustomClass2} ${styles.myCustomGreyChip}`}
                    />
                  </span>
                </Typography>
              </Grid>
              
            )}
            <Grid item style={{ width: "100%" }}>
              {orderDetailsData?.provider_contacts?.phone_number && (
                <Typography>
                  <span className={styles.customText3}>
                    {orderDetailsData?.provider_contacts?.phone_number.replace(
                      /(\d{3})-(\d{3})-(\d{4})/,
                      "($1) $2-$3"
                    )}
                    <PhoneIcon className={styles.smallMargin} />
                    <span className={styles.mobileText}>(Landline)</span>
                    {orderDetailsData?.provider_contacts?.extension && (
                      <span className={styles.mobileText}>
                        ({orderDetailsData?.provider_contacts?.extension})
                      </span>
                    )}
                  </span>
                </Typography>
              )}
              {orderDetailsData?.provider_contacts?.after_hours_phone && (
                <span className={styles.customText3}>
                  <span className={styles.customText1}>
                    After hours phone:&nbsp;
                  </span>
                  <span className={styles.customText3}>
                    {orderDetailsData?.provider_contacts?.after_hours_phone.replace(
                      /(\d{3})-(\d{3})-(\d{4})/,
                      "($1) $2-$3"
                    )}
                    <PhoneIcon className={styles.smallMargin} />
                    <span className={styles.mobileText}>(Landline)</span>
                    {orderDetailsData?.provider_contacts?.extension && (
                      <span className={styles.mobileText}>
                        ({orderDetailsData?.provider_contacts?.extension})
                      </span>
                    )}
                  </span>
                </span>
              )}
            </Grid>
            <Grid item style={{ width: "100%" }}>
              {orderDetailsData?.technician_details?.technician_name && (
                <hr className='hrMargin'/>
              )}
            </Grid>
            {orderDetailsData?.technician_details?.technician_name && (
              <Grid item>
                <Typography>
                  <span className={styles.customText1}>
                    {orderDetailsData?.technician_details?.technician_name}
                     
                  </span>
                  <Chip
                    label="Technician"
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: "var(--color-grey)",
                      margin: "0px 0px 0px 5px",
                      color: "#fff",
                    }}
                    className={`${styles.myCustomClass2} ${styles.myCustomGreyChip}`}
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" className={styles.customBox2}>
        <Box sx={{ marginBottom: "8px" }} className={styles.scopedClassName}>
          <div className={styles.scopedClassHeading}>
            {t("PatientsDetails.addresses")}
          </div>
        </Box>
            <CardContent sx={{ padding: "0px 0px 1px 16px" }}>
              <Grid container direction="column">
                {orderDetailsData?.address?.address_type == "RES" &&
                  orderDetailsData?.address?.address_type !== null && (
                    <Grid item>
                      <Typography>
                        <span className={styles.customText4}>Address Type:</span>
                        <span className={styles.customText6}>
                          {
                            FacilityTypeEnum[
                            orderDetailsData?.address?.address_type
                            ]
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}
                {orderDetailsData?.address?.location_name && (
                  <Grid item>
                    <Typography sx={{ marginBottom: "0px" }}>
                      <span className={styles.customText4}>
                        {t("PatientsDetails.facilityName")}:{" "}
                      </span>
                      <span className={styles.customText6}>
                        {orderDetailsData?.address?.location_name}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {orderDetailsData?.address?.address_type !== "RES" &&
                  orderDetailsData?.address?.address_type !== null && (
                    <Grid item>
                      <Typography sx={{ marginBottom: "0px" }}>
                        <span className={styles.customText4}>
                          {t("PatientsDetails.facilityType")}:{" "}
                        </span>
                        <span className={styles.customText6}>
                          {
                            FacilityTypeEnum[
                            orderDetailsData?.address?.address_type
                            ]
                          }
                        </span>
                      </Typography>
                    </Grid>
                  )}

                <Grid item>
                  <Typography
                    className={styles.marginTypography}
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "0",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{ margin: "0 !important", padding: "0" }}
                      className={`${styles.scopedClassColorText} ${styles.addressFontSize}`}
                    >
                      <p className="marginZero">
                        {orderDetailsData?.address?.address_line1 && (
                          <span>{orderDetailsData?.address?.address_line1},</span>
                        )}
                      </p>
                      <p className="marginZero">
                        {orderDetailsData?.address?.address_line2 && (
                          <span>{orderDetailsData?.address?.address_line2},</span>
                        )}
                      </p>
                      <p className="marginZero">
                        {orderDetailsData?.address?.city && (
                          <span>{orderDetailsData?.address?.city}, </span>
                        )}
                        {orderDetailsData?.address?.state && (
                          <span>{orderDetailsData?.address?.state}, </span>
                        )}
                        {orderDetailsData?.address?.zip_code && (
                          <span>{orderDetailsData?.address?.zip_code}, </span>
                        )}
                        {orderDetailsData?.address?.country && (
                          <span>{orderDetailsData?.address?.country}</span>
                        )}
                      </p>
                    </Typography>
                  </Typography>
                </Grid>
                {orderDetailsData?.address?.room_number && (
                  <Grid item>
                    <Typography>
                      <span className={styles.customText4}>Room #:</span>
                      <span className={styles.customText6}>
                        {orderDetailsData?.address?.room_number}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {orderDetailsData?.address?.bed_number && (
                  <Grid item>
                    <Typography>
                      <span className={styles.customText4}>Bed #:</span>
                      <span className={styles.customText6}>
                        {orderDetailsData?.address?.bed_number}
                      </span>
                    </Typography>
                  </Grid>
                )}
                {orderDetailsData?.address_notes && (
                  <Grid item>
                    <Typography>
                      <span className={styles.customText4}>Address Notes:</span>
                      <span className={styles.customText6}>{orderDetailsData?.address_notes}</span>
                    </Typography>
                  </Grid>)}
              </Grid>
            </CardContent>
      </Card>
      <Card variant="outlined" className={styles.customBox2} data-testid='orderDetailsContainer'>
        <Box sx={{ marginBottom: "8px" }} className={styles.scopedClassName} data-testid='orderDetails'><div className={styles.scopedClassHeading}>Order Details</div></Box>
        <CardContent sx={{ padding: "0px 0px 1px 16px" }}>
            <Grid container direction="column">
              {orderDetailsData?.total_equipment !== 0 &&
                orderDetailsData?.total_equipment && (
                  <Grid item className={styles.paddingBotom5px}>
                    <Typography>
                      <span className={styles.customText4}>
                      {t("Dashboard.uniqueEquipment")}:
                      </span>
                      <span className={styles.customText6}>
                        {orderDetailsData?.total_equipment}
                      </span>
                    </Typography>
                  </Grid>
                )}
                  {orderDetailsData?.orders?.parent_order_id > 0 && (
                    <Grid item className={styles.paddingBotom5px}>
                      <span className={styles.customText4}> {t("Dashboard.refernceOrderId")}:</span>
                      <Link to={`/orders/order-details/${orderDetailsData?.orders?.parent_order_id}`}
                        className={styles.routerLink}>
                        {orderDetailsData?.orders?.parent_order_id}
                      </Link>
                    </Grid>
                  )}
              {orderDetailsData?.equipment_requiring_approval !== 0 &&
                orderDetailsData?.equipment_requiring_approval && (
                  <Grid item className={styles.paddingBotom5px}>
                    <Typography>
                      <span className={styles.customText4}>
                        Equipment requiring approval:
                      </span>
                      <span className={styles.customText6}>
                        {orderDetailsData?.equipment_requiring_approval}
                      </span>
                    </Typography>
                  </Grid>
                )}
              {orderDetailsData?.formatted_ordered_on && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Ordered on:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.formatted_ordered_on}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.ordered_by && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Ordered by:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.ordered_by} (
                      {orderDetailsData?.ordered_by_company_name})
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.reason_code && (
                <Grid item>
                  <Typography sx={{ marginBottom: "5px" }}>
                    <span className={styles.customText4}>Order reason:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.orders?.order_type == "Pickup"? OrderReasonPickUpEnum[orderDetailsData?.reason_code] : OrderReasonEnum[orderDetailsData?.reason_code]}
                    </span>
                  </Typography>
                </Grid>
              )}

              {orderDetailsData?.priority_name && (
                <Grid item>
                  <Typography sx={{ marginBottom: "5px" }}>
                    <span className={styles.customText4}>Priority:</span>
                    <span className={styles.customText6}>
                      {`${orderDetailsData?.priority_name}`}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.orders?.requested_date_formatted && (
                <Grid item>
                  <Typography sx={{ marginBottom: "5px" }}>
                    <span className={styles.customText4}>Requested Date:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.orders?.requested_date_formatted.split(" ")[0]}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.orders?.reference && (
                <Grid item>
                  <Typography sx={{ marginBottom: "5px" }}>
                    <span className={styles.customText4}>Reference:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.orders?.reference}
                    </span>
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Box
                  flex="1"
                  display="flex"
                  alignItems={{ xs: "flex-start" }}
                  flexDirection="column"
                  sx={{ marginTop: { xs: 0, md: 0 } }}
                >
                  {orderDetailsData?.patient_nurse_details &&
                    orderDetailsData?.patient_nurse_details.length > 0 &&
                    orderDetailsData?.patient_nurse_details.some(
                      (patient) =>
                        patient.nurse_name !== null ||
                        patient.nurse_phone !== null
                    ) && (
                      <div
                        className={styles.clipAlignment}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span
                          className={styles.customText4}
                          style={{ marginRight: "4px", marginBottom: "0px" }}
                        >
                          Associated nurse:
                        </span>
                        {orderDetailsData?.patient_nurse_details?.map(
                          (patient, index) => (
                            <div
                              key={patient.nurse_name}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                key={patient.nurse_name}
                                title={
                                  patient?.nurse_phone && (
                                    <span className={styles.tooltipStyling}>
                                      {patient?.nurse_phone?.replace(
                                        /(\d{3})(\d{3})(\d{4})/,
                                        "($1) $2-$3"
                                      )}
                                    </span>
                                  )
                                }
                              >
                                {patient?.nurse_name && (
                                  <div key={patient.nurse_name}>
                                    <Chip
                                      label={patient?.nurse_name}
                                      size="small"
                                      className={styles.chipMargin}
                                    />
                                  </div>
                                )}
                              </Tooltip>
                            </div>
                          )
                        )}
                      </div>
                    )}
                </Box>
              </Grid>
              {orderDetailsData?.recurring_order_id > 0 && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Recurring order:</span>
                    <span
                      style={{ marginLeft: "8px" }}
                      className={styles.customLink}
                    >
                      <a
                        data-testid = "RecurringDetailPage"
                        onClick={() => {
                          handleRecurringDetailPage(
                            orderDetailsData?.recurring_order_id
                          );
                        }}
                      >
                        {orderDetailsData?.recurring_order_id}
                      </a>
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.approval_info?.formatted_approved_on && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Approved on:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.approval_info?.formatted_approved_on}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.approval_info?.approved_by && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Approved by:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.approval_info?.approved_by}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.approval_info
                ?.formatted_approval_rejected_on && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Rejected on:</span>
                    <span className={styles.customText6}>
                      {
                        orderDetailsData?.approval_info
                          ?.formatted_approval_rejected_on
                      }
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.approval_info?.approval_rejected_by && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Rejected by:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.approval_info?.approval_rejected_by}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.first_acknowledged && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Acknowledged:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData.first_acknowledged}
                    </span>
                  </Typography>
                </Grid>
              )}

              <Typography
                sx={{
                  paddingBottom: "5px",
                  fontSize: "var(--content-font-size-16px)",
                }}
                className={styles.scopedClassNameLine2}
              >
                  <OrderTypeText
                      orderType={orderDetailsData?.orders?.order_type}
                      orderStatus={orderDetailsData?.orders?.order_complete_status}
                      deliverOn={orderDetailsData?.formatted_delivered_on}
                      deliverBy={orderDetailsData?.deliver_by_formatted}
                      pickedUpOn={orderDetailsData?.delivered_on_formatted}
                      pickupBy={orderDetailsData.pickup_by_formatted}
                      serviceOn={orderDetailsData?.formatted_delivered_on}
                      serviceBy={orderDetailsData?.service_by_formatted}
                      mixOrderConfiguration={orderDetailsData?.orders?.mix_order_configuration}
                    />
              </Typography>
              {orderDetailsData?.cancellation_info?.formatted_canceled_on && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Canceled on:</span>
                    <span className={styles.customText6}>
                      {
                        orderDetailsData?.cancellation_info
                          ?.formatted_canceled_on
                      }
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.cancellation_info?.canceled_by && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Canceled by:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.cancellation_info?.canceled_by}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.cancellation_info?.canceled_reason && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Canceled reason:</span>
                    <span className={styles.customText6}>
                      {orderDetailsData?.cancellation_info?.canceled_reason}
                    </span>
                  </Typography>
                </Grid>
              )}
              {orderDetailsData?.signature?.signature_svg && (
                <Grid item className={styles.paddingBotom5px}>
                  <Typography sx={{ marginBottom: "0px" }}>
                    <span className={styles.customText4}>Signature:</span>
                    <span className={styles.customText6}>
                      <img
                        src={orderDetailsData.signature.signature_svg}
                        alt="Signature"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </span>
                  </Typography>
                </Grid>
              )}
            </Grid>
        </CardContent>
      </Card>
      {proofOfDelivery && Object.keys(proofOfDelivery).map((key) => {
        if (proofOfDelivery[key]) {
          return (
            <ProofOrderDetailCards
              key={key}
              proofData={proofOfDelivery[key]}
              orderDetailsData={orderDetailsData}
              setProofData ={setProofOfDelivery}
            />
          );
        }
        return null;
      })}
      {proofOfDelivery?.order_complete_status == "Complete" && (
        <Box className={styles.buttonContainer}>
          <Button
            variant="contained"
            size="small"
            className={styles.customButton}>
            Share feedback
          </Button>
        </Box>
      )}
        {(orderDetailsData?.orders?.order_complete_status == "Delivered" || orderDetailsData?.orders?.order_complete_status == "Picked Up") && (checkOrderFeedbackExpired(orderDetailsData?.delivered_on,orderFeedBackExpirationDays) && (!orderFeedbackData?.data?.is_form_submitted && !orderFeedbackData?.data?.is_link_expired)) &&
        <div className="textAlignEnd fontWeight600 marginBottom10px">
        <Link to={{pathname : `/orders/feedback-order`}} state={{orderFeedbackId : orderDetailsData?.orders?.order_id}} className={styles.customLink}>{t("SupportComponent.share_feedback")}</Link>
      </div>
      } 
      </div> : 
      <div>
      {isLoading && (
        <NoData
          message={isLoading && <img alt="Loading..." src={loader} />}
        />
      )}
    </div>}
      <CancelOrder
        isOpen={isCancelOrderPopupOpen}
        handleCancel={() => setIsCancelOrderPopupOpen(false)}
        handleSubmit={handleCancelOrder}
        orderName={alertEquipmentName}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        contentText={`Please enter the reason for canceling this order:`}
        contentText2={`Reason`}
        headerText="Cancel Order"
        cancelText="Go Back"
        okText="Proceed"
      />
    </div>
  );
};

export default withTitle(OrdersDetails, "Order Details");
