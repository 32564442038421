import React from 'react';
import { Button } from '@mui/material';
import styles from './CustomButtons.module.scss';

const CustomButtons = ({ type, text, onClick, dataTestid = "CustomBtn", variant = "contained" }) => (
  <div className={styles.CustomButtons} data-testid="CustomButtons">
    <Button
      data-testid={dataTestid}
      type={type}
      className={type === 'button' ? 'secondaryButton' : 'primaryButton'}
      onClick={onClick}
      variant={variant}
    >
      {text}
    </Button>
  </div>


);


export default CustomButtons;
