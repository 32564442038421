import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Box, Button } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";

const ElectricLiftSystem = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();
    return (
        <>
            {preAuthFormData && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={true}
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>{t("PreAuthForm.electricLiftSystem")}</DialogTitle>
                    <DialogContent>
                        <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor">* </span>{t("PreAuthForm.requiredField")}</Typography>
                        <Grid container>
                            <Grid item xs={12} md={6}  >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>{t("PreAuthForm.branchCode")}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={preAuthFormData[0].BranchCode}
                                            sx={{ marginTop: '5px', width: "220px", backgroundColor: 'var(--color-disableField)', borderRadius:"8px", '& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              }, }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {preAuthFormData[0]?.PatientResidence && <Grid container>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. {t("PreAuthForm.patientResides")}:<span className="asteriskSignColor"> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].PatientResidence}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Home" control={<Radio />} label="Home" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="SNF/ALF" control={<Radio />} label="SNF/ALF" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsCaregiverUseManualLift !== "" && preAuthFormData[0]?.IsCaregiverUseManualLift !== undefined  && <Grid container>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>2.&nbsp;</div><div>{t('PreAuthForm.hoyerLiftSystem')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsCaregiverUseManualLift}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.CaregiverUnableManualLiftReasons && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <div>
                                <Typography className={`${styles.labelText} displayFlex`}>
                                    <div>a.&nbsp;</div><div>{t("PreAuthForm.manualFootLift")}<span className="asteriskSignColor"> *</span></div>
                                </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="minimum height"
                                    minRows={2}
                                    multiline
                                    rows={3}
                                    size="small"
                                    value={preAuthFormData[0].CaregiverUnableManualLiftReasons}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined  && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"> 3. {t('PreAuthForm.physicianOrder')}<div className="asteriskSignColor">&nbsp;*</div></FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle:"italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsPhysicianOrder}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPatientHasElectricalLift !== "" && preAuthFormData[0]?.IsPatientHasElectricalLift !== undefined  && <Grid container>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>4.&nbsp;</div><div>{t('PreAuthForm.patientElectricLift')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsPatientHasElectricalLift}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.RationaleForElectricalLift && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <div>
                                <Typography className={`${styles.labelText} displayFlex`}>
                                        <div>a.&nbsp;</div><div>{t("PreAuthForm.electricLiftRationale")}<span className="asteriskSignColor"> *</span></div>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0].RationaleForElectricalLift}
                                    size="small"
                                    sx={{ marginTop: '5px',borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsExtenuatingCircumstancesForElectricLift  !== "" && preAuthFormData[0]?.IsExtenuatingCircumstancesForElectricLift !== undefined  && <Grid container>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 5. {t("PreAuthForm.extenuatingElectricLift")}<span className="asteriskSignColor"> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsExtenuatingCircumstancesForElectricLift}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.CaregiverCannotUseManualLiftExplanation && <Grid container sx={{ paddingLeft:'19px'}}>
                            <Grid  xs={12} className={styles.spacingTop}>
                                <div>
                                <Typography className={`${styles.labelText} displayFlex`}>
                                        <div>a.&nbsp;</div><div>{t("PreAuthForm.manualPneumaticLift")}<span className="asteriskSignColor"> *</span></div>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="minimum height"
                                    minRows={2}
                                    multiline
                                    rows={3}
                                    size="small"
                                    value={preAuthFormData[0].CaregiverCannotUseManualLiftExplanation}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsFacilityRequested !== "" && preAuthFormData[0]?.IsFacilityRequested !== undefined  &&  <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">6. {t('PreAuthForm.facilityRequested')}<span className="asteriskSignColor"> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsFacilityRequested}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.FacilityRequestingName && <Grid container sx={{ paddingLeft: '10px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.nameOfFacility')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    minRows={2}
                                    aria-label="requesting custom text"
                                    size="small"
                                    fullWidth
                                    value={preAuthFormData[0].FacilityRequestingName}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.clinicalName')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinic text field"
                                    value={preAuthFormData[0].ClinicianName}
                                    fullWidth
                                    minRows={2}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.edAdmin")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0].AdministratorOrED}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.avpo")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0].AVPO}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "end", padding: "14px 16px"}}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className='secondaryButton'
                        >
                            {t('NewOrder.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

ElectricLiftSystem.formName = "ElectricLiftSystem";
export default ElectricLiftSystem;