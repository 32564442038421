import React from "react";
import { Typography, Button, FormGroup, Checkbox, FormControl, FormLabel, FormControlLabel,Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle } from "@mui/material";
import styles from './FormAuth.module.css';


const BrodaAuthorizationForm = ({handleClose, preAuthFormData}) => {
  
    return (
      <>
      {preAuthFormData  && (
           
           <Dialog 
        fullWidth
        maxWidth="md"
        open={true} 
        onClose={handleClose} 
        >
        <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> HTR/Broda Chair Pre-Authorization Documentation  </DialogTitle>
        
        
        <DialogContent>
        <Typography sx={{margin: '10px 0px'}}><span className={styles.asterisk}>*</span> denotes required field</Typography>
           {preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' &&  <Grid container>
            <Grid xs={12} md={6} className={styles.spacingTop} sx={{marginTop:'0px !important'}}>
            <FormControl className={styles.spacingRemoveTop}>  
              <div>
                <Typography className={styles.labelText}> Branch/State (3 letter code) <span className={styles.asterisk}>*</span> </Typography>
              </div>
              <div >
              <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  margin="normal"
                  size="small"
                  type="text"
                  variant="outlined"
                  maxLength="3"
                  value={preAuthFormData[0].BranchCode}
                  disabled={true}
                  sx={{marginTop: '5px', backgroundColor:'var(--color-disableField)'}}
                  inputProps={{ style: { cursor: 'not-allowed' } }}
              />
              </div>
              </FormControl>
            </Grid>
          </Grid>}

{preAuthFormData[0]?.IsSelfMobile !== undefined && preAuthFormData[0]?.IsSelfMobile !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. Is the patient self-mobile?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
        value={preAuthFormData[0].IsSelfMobile}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsPositioningAssistanceRequired !== undefined && preAuthFormData[0]?.IsPositioningAssistanceRequired !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 2. Is positioning assistance required?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].IsPositioningAssistanceRequired}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsTiltRequired !== undefined && preAuthFormData[0]?.IsTiltRequired !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. Does the patient require tilt in space feature for proper positioning during daily activities such as eating?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].IsTiltRequired?"Yes":"No"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.HasMusculoskeletalHip !== undefined && preAuthFormData[0]?.HasMusculoskeletalHip !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 4. Does the patient have significant trunk or hip musculoskeletal deformity or abnormal tone in the trunk musculature and must be tilted to maintain postural control or spinal alignment?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].HasMusculoskeletalHip?"Yes":"No"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsUnableToChangePosition !== undefined && preAuthFormData[0]?.IsUnableToChangePosition !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 5. Is the patient unable to actively change their upright seating position and is at a risk for loss of skin integrity?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].IsUnableToChangePosition?"Yes":"No"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.IsSpend6hWheelchair !== undefined && preAuthFormData[0]?.IsSpend6hWheelchair !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 6. Will the patient spend a minimum of six hours per day in the wheelchair?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].IsSpend6hWheelchair?"Yes":"No"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}

{preAuthFormData[0]?.HasDysfunction !== undefined && preAuthFormData[0]?.HasDysfunction !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}>
  <FormControl className={styles.spacingRemoveTop}>  
<FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 7. Does the patient have a respiratory, digestive, or cardiac dysfunction that is functionally improved with the tilt/recline feature?<span className={styles.asterisk}>*</span></FormLabel>
      <RadioGroup
      value={preAuthFormData[0].HasDysfunction?"Yes":"No"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={true}/>
        <FormControlLabel value="No" control={<Radio />} label="No" disabled={true}/>
        
      </RadioGroup>
    </FormControl>
  </Grid>
</Grid>}


{preAuthFormData[0]?.DysfunctionDesc !== undefined && preAuthFormData[0].DysfunctionDesc !== '' && <Grid container sx={{paddingLeft:'10px'}}>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
       a. If yes, please describe:
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            multiline
            rows={3}
            maxLength={4000}
            size="small"
            value={preAuthFormData[0].DysfunctionDesc}
            disabled={true}
            sx={{marginTop:'5px',
            resize: 'verical',
            overflow: 'auto', 
            maxHeight: '300px',
            backgroundColor: "var(--color-disableField)"
            }}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



{preAuthFormData[0]?.OtherCircumstancesDesc !== undefined && preAuthFormData[0].OtherCircumstancesDesc !== ''  && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
   8. Please describe other extenuating circumstances that require the patient to use an HTP/Broda chair: <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            multiline
            rows={3}
            maxLength={500}
            size="small"
            value={preAuthFormData[0].OtherCircumstancesDesc}
            aria-label="minimum height"
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}




{preAuthFormData[0]?.PriorEquipmentRequestDesc !== undefined && preAuthFormData[0].PriorEquipmentRequestDesc !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
   9. What formulary items have been tried prior to requesting the Broda, such as a high-back wheelchair or Gerichair? <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={4000}
            size="small"
            value={preAuthFormData[0].PriorEquipmentRequestDesc}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.isRequestedBySNFALF !== undefined && <Grid container>
     <Grid xs={12}>
     <FormControl className={styles.spacingRemoveTop}>  
     <FormGroup>
         <FormControlLabel control={<Checkbox disabled={true} checked={preAuthFormData[0].isRequestedBySNFALF} />} label="Requested by SNF/ALF as their facility standard" />
       </FormGroup>
       </FormControl>
     </Grid>
   </Grid>}


{preAuthFormData[0].RequestingFacility !== '' && preAuthFormData[0]?.RequestingFacility !== undefined && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
    Name of Requesting Facility <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            fullWidth
            aria-label="minimum height"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].RequestingFacility}
            sx={{marginTop:'5px',cursor:'not-allowed', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.ClinicianName !== undefined && preAuthFormData[0].ClinicianName !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
        Clinician Name <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            aria-label="Broda clinicianName"
            fullWidth
            inputProps={{ style: { cursor: 'not-allowed' } }}
            sx={{backgroundColor:'var(--color-disableField)', marginTop:'5px', cursor:'not-allowed'}}
            minRows={2}
            size="small"
            value={preAuthFormData[0].ClinicianName}
            disabled={true}
            maxLength={200}
          />
  </Grid>
</Grid>}


{preAuthFormData[0].ApprovingManager !== '' && preAuthFormData[0]?.ApprovingManager !== undefined  && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            ED/Admin <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
            maxLength={4000}
            fullWidth
            disabled={true}
            aria-label="Broda Ed/adminField"
            size="small"
            minRows={2}
            value={preAuthFormData[0].ApprovingManager}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)', cursor:'not-allowed'}}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}

{preAuthFormData[0]?.AVPO !== undefined && preAuthFormData[0].AVPO !== '' && <Grid container>
  <Grid xs={12} className={styles.spacingTop}> 
  <div>
    <Typography className={styles.labelText}>
            AVPO <span className={styles.asterisk}>*</span>
    </Typography>
  </div>
  <TextField
  fullWidth
            aria-label="Avpo Broda field"
            minRows={2}
            maxLength={200}
            size="small"
            value={preAuthFormData[0].AVPO}
            sx={{marginTop:'5px', backgroundColor:'var(--color-disableField)'}}
            disabled={true}
            inputProps={{ style: { cursor: 'not-allowed' } }}
          />
  </Grid>
</Grid>}



      
        </DialogContent>

<Grid container sx={{justifyContent:'end'}}>
  <Grid>
  <DialogActions
              style={{ justifyContent: "end", padding: "14px 16px" }}
            >
              <Button
                sx={{textTransform:'none'}}
                onClick={handleClose}
                className='secondaryButton'
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
  </Grid>
</Grid>
        
      </Dialog>
      )}
     
    </>
    )
}

BrodaAuthorizationForm.formName = "BrodaAuthorizationForm";
export default BrodaAuthorizationForm;