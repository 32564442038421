import { useState } from "react";
import { AntSwitch } from "../../components/MuiCustomComponent/MuiCustomComponent";

export const ImageToggle = ({ hideEquipmentImage, setHideEquipmentImage }) => {
  const toggleEquipmentImage = (e) => {
    setHideEquipmentImage(e.target.checked);
  };

  return (
    <>
      <div className="displayFlex alignItemCenter" data-testid='image-toggle'>
        <AntSwitch
          data-testid="antSwitch"
          inputProps={{ "aria-label": "Hide Images" }}
          checked={hideEquipmentImage}
          onChange={toggleEquipmentImage}
        />
        <p style={{color:"var(--color-dimgray)"}} className="marginLeftEight">Hide images</p>
      </div>
    </>
  );
};
